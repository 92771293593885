<template>
  <div>
    <br />
    <div class="row">
      <div class="col-xl-3 col-12">
        <label for=""> Prénom </label>
        <input type="text" v-model="customer.firstName" class="form-control" />
      </div>

      <div class="col-xl-3 col-12">
        <label for=""> Nom </label>
        <input type="text" v-model="customer.lastName" class="form-control" />
      </div>

      <div class="col-xl-3 col-12">
        <label for="">CIN</label>
        <input type="text" v-model="customer.cin" class="form-control" />
      </div>

      <div class="col-xl-3 col-12">
        <label for="">Téléphone</label>
        <input type="text" v-model="customer.phone" class="form-control" />
      </div>
    </div>

    <br />
    <div class="row">
      <div class="col-xl-3 col-12">
        <label for="">E-mail</label>
        <input type="text" v-model="customer.email" class="form-control" />
      </div>

      <div class="col-xl-3 col-12">
        <label for="">Nom Comerciale</label>
        <input type="text" v-model="customer.society" class="form-control" />
      </div>

      <div class="col-xl-3 col-12">
        <label for=""> ICE </label>
        <input type="text" v-model="customer.ice" class="form-control" />
      </div>

      <div class="col-xl-3 col-12">
        <label for="">Type</label>
        <select
          name=""
          id=""
          v-model="customer.customerType_reference"
          class="form-select"
        >
          <option
            v-for="(customer_type, index) in customer_types"
            :key="index++"
            :value="customer_type.reference"
          >
            {{ customer_type.fullName }}
          </option>
        </select>
      </div>

      <div class="col-xl-3 col-12">
        <label for="">Montant Impayé Maximum</label>
        <input type="text" v-model="customer.maxUnpaid" class="form-control" />
      </div>
    </div>

    <div class="row mt-3">
      <div class="col">
        <label for=""> Remarque </label>
        <textarea
          v-model="customer.remark"
          class="form-control"
          cols="30"
          rows="3"
        ></textarea>
      </div>
    </div>

    <div class="row mt-3 justify-content-between">
      <div class="col-auto"></div>
      <div class="col-auto">
        <button class="btn btn-success" @click="save(customer)">
          <i class="bi bi-check-square-fill"></i> Enregistrer
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      customer: {
        maxUnpaid: 0,
      },
    };
  },
  computed: {
    ...mapGetters("customer", {
      customer_types: "getCustomerTypes",
    }),
  },
  methods: {
    async save(data) {
      await this.$store.dispatch("customer/store", data);
    },
    async empty() {
      this.$store.commit("customer/setCustomer", {});
    },
  },
  beforeMount() {
    this.$store.dispatch("customer/getCustomerTypes");
  },
};
</script>
